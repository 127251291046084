/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "@stripe/stripe-js" // https://github.com/stripe/stripe-js#import-as-a-side-effect
import "@fontsource/abril-fatface"

import Header from "./header"
import "./layout.css"

const Layout = ({ backgroundColor, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div style={{"backgroundColor": backgroundColor}} className="flex flex-col min-h-screen">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className="flex-grow p-4">
        <main className="max-w-6xl m-auto text-center">{children}</main>
      </div>
      <footer className={`w-full mt-4 mb-0 text-center bg-white p-4 text-gray-900`}>
        © {new Date().getFullYear()} Met Hamer en Vork
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
