import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Header = () => (
  <header className="sticky top-0 z-10 w-full text-gray-800 bg-white">
    <div className="flex flex-row-reverse items-center px-4">
      <Link
        className="p-4 font-semibold transition-colors duration-200 ease-out bg-white hover:text-white hover:bg-blue-gray-700"
        to="/checkout"
      >
        Order now →
      </Link>
      <div className="flex-grow">
        <Link to="/" className="text-lg font-semibold text-gray-800 ">
          Bento Box
        </Link>
        <Link to="/about" className="ml-4 text-lg text-gray-800 ">
          About
        </Link>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
