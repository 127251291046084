import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"

const AboutPage = () => {
  return (
    <Layout>
      <div className="grid max-w-4xl gap-4 m-auto text-gray-800 lg:grid-cols-2">
        <div className="p-4 leading-relaxed text-left bg-white rounded about-content">
          <h3 className="text-xl">
            Welcome to Bento Kitchen! Bento Kitchen is a project by Met Hamer en Vork, to make
            people happy around lunchtime.
          </h3>
          <p>
            <em>Apart from</em> Bento Kitchen, <em>Met Hamer en Vork</em> caters cosy
            lunches, festive dinners and food events run by Veronica Hamer. With
            a background in Art History, many years ago I started as an
            assistant helping produce exhibitions. While I was busy checking the
            state of frames, I slowly came to the realization that I cared even
            more about providing daily lunches for the team than the actual
            paintings. I discovered that cooking food is a great way to use my
            creativity and to make people happy. Soon I began to organize dinner
            parties. They grew in size and turned out to be a great success;
            guests left smiling and asking for the next one to come.
          </p>
          <p>
            In 2016, I started my own catering company. Met Hamer en Vork has
            organized countless private dinners and catered for various food
            events. For small events I work solo, and for bigger ones I team up
            with befriended freelance cooks. Memorable dishes, balanced flavours
            and beautiful plates are the common factors.
          </p>
          <p>
            From 2017 to 2020, I worked as a lunch chef for a young tech company
            in Amsterdam, MessageBird. It started off with about 50 mouths to
            feed, but the company grew quickly and soon over 300 employees were
            looking forward to my dishes. During this time my interest in
            healthy eating grew, striving for the right balance of micro and
            macro nutrients in every meal. To optimize my knowledge in this
            area, I’m now training in dietetics. Fall 2020 meant the start of
            Bento Kitchen, to bring joy around lunchtime for all the people working
            from home.
          </p>
          <p>
            I always look forward to helping others with food-related questions
            or planning needs. Feel free to contact me with any questions or to
            discuss your plans, and I’ll share some ideas and possibilities with
            you.
          </p>
        </div>
        <div className="row-start-1 overflow-hidden rounded lg:col-start-2 about-image">
          <StaticImage
            src="../images/veronica.jpg"
            width={768}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Bento Box"
          />
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage
